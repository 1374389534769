import "../../styles/projects/proyects.css";
import { useEffect, useRef, useState } from "react";
import { projects } from "./proyectList";
import ButtonRef from "../helpers/buttonRef";

const ProyectsContainer = () => {
    const [ lastActive, setLastActive ] = useState<string>("active_0");
    const ref = useRef<HTMLDivElement | null>(null);

    const slide = (shift: number, index: number): any => {
        if (!ref.current) 
            return;

        const xLength = (ref.current.getBoundingClientRect().width + 16) * projects.length;
        const divider = xLength / projects.length;
        const multiplier = divider * index;

        ref.current!.scrollTo({
            left: multiplier,
            behavior: "smooth"
        });

        setAnimation(`active_${index}`);

        return;
    };

    const setAnimation = (id: string): void => {
        if (lastActive === id)
            return;
            
        document.getElementById(id)?.classList.add("true");
        document.getElementById(lastActive)?.classList.remove("true");

        setLastActive(id);
    };

    useEffect(() => {
        document.getElementById(lastActive)?.classList.add("true");
    }, []);

    return (
        <main className="projects">
            <nav className="buttonsList" data-aos="fade-up" data-aos-duration="1000">
                {projects.map((item, index )=> (
                    <ButtonRef key={index.toString()} onClickCustom={slide} id={index} shift={50}/>
                ))
                }
            </nav>
            <nav ref={ref} data-aos="fade-up-left" data-aos-anchor-placement="button-center" data-aos-duration="1000" className="projects-container">
                {projects.map((Item)=> Item)
                }
            </nav>
        </main>
    )
};

export default ProyectsContainer;