import buttonRefProps from "../../interfaces/props/buttonRef"
import "../../styles/helpers/buttonRefs.css";

const ButtonRef = (props: buttonRefProps)  => {
    const handleClick = () => {
        props.onClickCustom(props.shift, props.id);
    }

    return (
        <div className="border-white" onClick={handleClick}>
            <div className="false transition" id={`active_${props.id.toString()}`}>
            </div>
        </div>
    )
}

export default ButtonRef; 