import { FormEventHandler, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import "../../../styles/skills/form/form.css";
import formData from '../../../interfaces/form/login.interface';
import axios, { AxiosError, AxiosResponse } from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { FaLock, FaUnlockAlt } from "react-icons/fa";

function FormExample() {
  const [ formData, setFormData ] = useState<formData>({
    name: '',
    email: '',
    lastName: '',
    password: '',
    username: '',
    birthday: new Date("2005-08-01")
  })
  const [validated, setValidated] = useState<boolean>(false);
  const [ active1, setActive1 ] = useState<boolean>(false);
  const [ active2, setActive2 ] = useState<boolean>(true);
  const [ passwordHidden, setPasswordHidden ] = useState<boolean>(true);
  const apiRoute = "https://login-service-2k2v-dev.fl0.io/v1/";

  const handleChange: React.ChangeEventHandler<any> | undefined = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      handleError();
      return;
    }

    setValidated(true);

    formData.birthday = new Date(formData.birthday);
    
    switch(true) {
      case active1:
        postHandler("login");
        break;
      case active2:
        postHandler("signin");
        break;
      default:
        console.log("No action recogniced");
        return;
    }
  };

  const postHandler = async (type: string) => {
    await axios.post(`${apiRoute}${type}`, formData, {
      headers: { 
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    })
    .then((res: AxiosResponse) => {
      handleSuccess(res, type);
    })
    .catch((error: AxiosError) => {
      handleError(error);
      return;
    })
  };

  const handleSuccess = (res: AxiosResponse, type: string): void => {
    const response = res.data;

    if (type === "signin")
      toast.success(response.message ? response.message : "User Created Successfully");

    lgoinMessage(response.username);

    resetFormData();

    localStorage.setItem('token', JSON.stringify(res.data));
  };

  const handleError = (err?: AxiosError<any>): void => {
    if (!err) {
      toast.error("Complete the form");
      return;
    }

    toast.error(err.response ? err.response.data.message : "Unknown error");
  };

  const chagenActiveStatus = (setter1: React.Dispatch<React.SetStateAction<boolean>>, setter2: React.Dispatch<React.SetStateAction<boolean>>): void  => {
    setter1(true);
    setter2(false);
    resetFormData();
  }

  const resetFormData = () => {
    setFormData({
      name: '',
      email: '',
      lastName: '',
      password: '',
      username: '',
      birthday: new Date().toDateString()
    });
    
    setPasswordHidden(true);
  };

  useEffect(() => {

  }, [validated]);

  const lgoinMessage = (username: string) => {
    toast.success(`Welcome ${username}!`, {
      duration: 4000,
      position: 'top-center',
    });
  };

  const showPasswordStatus = () => {
    setPasswordHidden(!passwordHidden);
  }

  return (
    <div className='formExaple'>
        <div className='title-form'>
            <h3>Validations</h3>
        </div>
        <div className='formExample-container'>
            {active2 ?
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <Form.Label>First name</Form.Label>
                      <Form.Control
                          required
                          type="text"
                          placeholder="First name"
                          name="name"
                          onChange={handleChange}
                          value={formData.name}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustom02">
                      <Form.Label>Last name</Form.Label>
                      <Form.Control
                          required
                          type="text"
                          placeholder="Last name"
                          name="lastName"
                          onChange={handleChange}
                          value={formData.lastName}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustom04">
                      <Form.Label>Email</Form.Label>
                      <Form.Control 
                        type="email" 
                        placeholder="Email" 
                        name="email" 
                        onChange={handleChange} 
                        required
                        value={formData.email}
                      />
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} md="5" controlId="validationCustom03">
                    <Form.Label>Password</Form.Label>
                      <InputGroup>
                        <Form.Control 
                          type={passwordHidden ? "password" : "text"} 
                          placeholder="Password" 
                          name="password" 
                          onChange={handleChange}
                          required
                          value={formData.password}
                        />
                        <Button variant="secondary" onClick={showPasswordStatus}>
                          {passwordHidden ? <FaLock/> : <FaUnlockAlt/> }
                        </Button>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustomUsername">
                      <Form.Label>Username</Form.Label>
                      <InputGroup hasValidation>
                          <Form.Control
                          type="text"
                          placeholder="Username"
                          aria-describedby="inputGroupPrepend"
                          name="username"
                          onChange={handleChange}
                          value={formData.username}
                          required
                          />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationCustom05">
                      <Form.Label>Birthday</Form.Label>
                      <Form.Control 
                        type="date" 
                        placeholder="Birthday" 
                        name="birthday" 
                        onChange={handleChange}
                        max="2005-08-01"
                        required
                        value={formData.birthday.toString()}
                      />
                    </Form.Group>
                </Row>
                <Button variant="outline-light" type="submit">Submit</Button>
              </Form>
            : 
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom04">
                  <Form.Label>Email</Form.Label>
                  <Form.Control 
                    type="email" 
                    placeholder="Email" 
                    name="email" 
                    onChange={handleChange} 
                    required 
                    value={formData.email}
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>Password</Form.Label>
                    <InputGroup>
                      <Form.Control 
                        type={passwordHidden ? "password" : "text"} 
                        placeholder="Password" 
                        name="password" 
                        onChange={handleChange}
                        required
                        value={formData.password}
                      />
                      <Button variant="secondary" onClick={showPasswordStatus}>
                        {passwordHidden ? <FaLock/> : <FaUnlockAlt/> }
                      </Button>
                    </InputGroup>
                </Form.Group>
              </Row>
                <Button variant="outline-light" type="submit">Submit</Button>
              </Form>
            }
        </div>
        <div className='typeSelector'>
            <Button variant="outline-light" active={active1} onClick={() => chagenActiveStatus(setActive1, setActive2)} id="login">Log In</Button>
            <Button variant="outline-light" active={active2} onClick={() => chagenActiveStatus(setActive2, setActive1)} id="sigin">Sign in</Button>
        </div>
        <Toaster
            position="bottom-center"
            reverseOrder={false}
        />
    </div>
  );
}

export default FormExample;