import "../../../styles/projects/coffeStyles/coffeStyles.css"

const coffe = () => {
    const redirect = (e: any) => {
        e.preventDefault();
        window.open("https://xenodochial-tereshkova-ade30c.netlify.app"); 
    };

    return (
        <section className="coffe">
            <div className="shadow-box-1"></div>
            <div className="image-container" onClick={redirect}>
                <div className="coffe-img"></div>
            </div>
            <div className="title-box-1 flex-center">
                <h1>Coffe Blog</h1>
            </div>
            <div className="description-box-1">
                <p>The Coffee Blog project aims to create an engaging online platform where coffee enthusiasts can gather to explore, read, and learn new coffee recipes. It serves as a hub for individuals who are passionate about coffee and want to discover unique and exciting ways to enjoy their favorite beverage.</p>
            </div>
            <div className="pagination-2">
                    <p>02/05 <span className="line">_____________</span></p>
            </div>
        </section>
    )
};

export default coffe;