import "../../styles/frontpage/frontpage.css"
import { BsPhoneFill } from "react-icons/bs";
import { HiMail } from "react-icons/hi";
import Button from "react-bootstrap/Button";
import { useEffect, useState } from "react";
import Blackline from "../helpers/blackline";

const Frontpage = () => {
    // Create a new instance of the Frontpage component with the default settings
    const [clicked1, setClicked1] = useState<boolean>(false);
    const [clicked2, setClicked2] = useState<boolean>(false);

    // Redirect on click to the work pages
    const redirect = (e: any) => {
        e.preventDefault();
        switch (e.target.id) {
            case "github": window.open("https://github.com/IvanCastro19"); break;
            case "linkedin": window.open("https://www.linkedin.com/in/ivan-castro-martinez-0057931b4/"); break;
        };
    };

    // Copy the id parameter in the current target
    const copyContent = (e: any) => {
        e.preventDefault();
        const event: string = e.target.id;

        switch(true) {
            case event.includes(".com"): {
                setClicked1(true)
                window.location.href = `mailto:${event}`;
                break;
            }
            case event.includes("52"): {
                setClicked2(true);
                window.location.href = `tel:${event}`;
                break;
            }
        };
    };

    // Download my cv file once the button is clicked
    const downloadCV = (e: any) => {
        e.preventDefault();
        const alink = document.createElement("a");

        alink.href = require('../../doc/CV.pdf') ;
        alink.target = "_blank";
        alink.download = "IvanCastroCV.pdf";
        alink.click();
    }

    // set to the default style after a button was clicked
    useEffect( () => {
        setTimeout( () => {
            clicked1 ? setClicked1(false) : setClicked2(false);
        }, 200);
    }, [clicked1, clicked2]);

    return (
        <>
            <div className="frontpage">
                <div className="frontpage__container">
                    <div className={`frontpage_box_1`}>
                        <p>01/05 <span className="line">_____________</span></p>
                    </div>
                    <div className="frontpage_box_2">
                        <div className="blackbox">
                            <div className="backbox-content">
                                <div className="blackbox-title">
                                    <header>Ivan Castro</header>
                                </div>
                                <div className="blackbox-description">
                                    <p>Fullstack Developer</p>
                                </div>
                                <div className="contactme">
                                    <div className="contactme-content">
                                        <div className="contactme-description">
                                            <div className="contactmethods">
                                                <p onClick={copyContent} className={`text-button-black ${clicked1 ? "isClicked" : ""}`} id="ivancastromartinezkk@gmail.com"><HiMail/> ivancastromartinezkk@gmail.com</p>
                                            </div>
                                            <div className="contactmethods">
                                                <p onClick={copyContent} className={`text-button-black ${clicked2 ? "isClicked" : ""}`} id="524491120164"><BsPhoneFill/> (449)-112-0164</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="blackbox-social ">
                                    <Button variant="outline-dark" id="github" onClick={redirect}>GitHub</Button>
                                    <Button variant="outline-dark" id="linkedin" onClick={redirect}>Linkedin</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="frontpage_box_3">
                        <div className="mycover">
                            <div className="mycover-content">
                                <div onClick={downloadCV} className="mycover-description">
                                    <p>Fullstack Developer with 3 years of experience and a strong background <br />in JavaScript, Typescript and Python development.<br /><br />
                                    As a motivated and detail-oriented professional, I thrive in dynamic <br /> environments and always eager to learn and adapt to new technologies <br /> and challenges. I pride myself on delivering high-quality software solutions  <br /> and a strong focus on problem-solving. <br /><br />
                                    I have attached my resume for your review in expect to discuss how my skills <br /> and experience can contribute to your team's success.
                                    </p>
                                    <p>Download CV</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Blackline text="Some of my Projects"/>
            </div>
        </>
    );
};

export default Frontpage;