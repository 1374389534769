import { useEffect, useState } from 'react';
import './App.css';
import Frontpage from "./components/fronpage/fronpage";
import ProyectsContainer from './components/projects/proyects';
import Cover from './components/cover';
import Blackline from './components/helpers/blackline';
import SkillsContent from './components/skills';
function App() {
  const [font, setFont] = useState("Lora-Regular");
  const plattform = window.navigator.platform;

  useEffect(() => {
    switch(true) {
      case plattform.includes("Mac"):
        setFont("Lora-Regular");
        break;
      default:
        setFont("arial");
        break;
    }
  }, []);

  return (
    <div className={`App ${font}`}>
      <div id="container-page" className='container-page max-height'>
        <Cover/>
        <div className='content-page'>
          <Frontpage/>
          <ProyectsContainer/>
          <Blackline text="Skills"/>
          <SkillsContent/>
        </div>
      </div>
    </div>
  );
}

export default App;
