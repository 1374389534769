import "../../styles/helpers/blackline.css";
import blacklineprops from "../../interfaces/props/bl_props.interface";

const blackline = (props: blacklineprops) => {
    
    
    return(
        <>
            <main className="black-bg wite-text padding-4 font-4">
                <div data-aos="fade-up" data-aos-duration="800">
                    <p>{props.text}</p>
                </div>
            </main>
        </>
    )
};

export default blackline;