import { useEffect, useState } from "react";
import "../../../styles/skills/weather/weather.css";
import axios from "axios";
import { TiWeatherSunny, TiWeatherCloudy, TiWeatherDownpour, TiWeatherStormy, TiWeatherWindy } from "react-icons/ti";
import { WiHumidity } from "react-icons/wi";
import { RiWindyFill, RiMistLine } from "react-icons/ri";
import toast, { Toaster } from "react-hot-toast";

const Weather = () => {
    const [ WeatherType, setWeatherType ] = useState<string>("");
    const [ city, setCity ] = useState<string>("CALIFORNIA");
    const [ temperature, setTemperature ] = useState<string>("");
    const [ weatherDescription, setWeatherDescription ] = useState<string>("");
    const [ humidity, setHumidity ] = useState<string>("");
    const [ windSpeed, setWindSpeed ] = useState<string>("");

    const APIKey = 'df2a6ca0d6eaf29f8dfee0fba848746f';
    let url = `https://api.openweathermap.org/data/2.5/weather?q=${city}&units=metric&appid=${APIKey}`

    const handleWeather: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault();

        if(city === "")
            return;

        getWeather();
    };

    const getWeather = async () => {
        await axios(url).then((response) => {
            const {data} = response;
            const type = data.weather[0].main;

            setWeatherType(type);
            setTemperature(`${parseInt(data.main.temp)}°C`);
            setWeatherDescription(data.weather[0].description);
            setHumidity(`${data.main.humidity}%`);
            setWindSpeed(`${data.wind.speed}Km/h`);
        }).catch(() => {
            notify();
        });
    };

    const notify = () => toast.error('City not found');

    useEffect(() => {getWeather()}, [])

    const changeCity:React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setCity(e.target.value)
    };

    return(
        <section className="weather skill-second">
            <div className="weather-container">
                <div className="input-query">
                    <i className="icon-weather fa-solid fa-location-dot"></i>
                    <input value={city} onChange={changeCity} type="text" placeholder="Enter your location"/>
                    <button onClick={handleWeather} className="weather-button fa-solid fa-magnifying-glass"></button>
                </div>
                <div className="weather-data">
                    { WeatherType === "Clear" ? 
                        <div>{<TiWeatherSunny size={60} color="white"/>}</div> 
                        : WeatherType === "Rain" ?
                        <div>{<TiWeatherDownpour size={60} color="white"/>}</div>
                        : WeatherType === "Snow" ?
                        <div>{<TiWeatherStormy size={60} color="white"/>}</div>
                        : WeatherType === "Clouds" ?
                        <div>{<TiWeatherCloudy size={60} color="white"/>}</div>
                        : WeatherType === "Haze" ?
                        <div>{<TiWeatherWindy size={60} color="white"/>}</div>
                        : WeatherType === "Mist" ?
                        <div>{<RiMistLine size={60} color="white"/>}</div>
                        : null
                    }
                    <p className="temperature">{temperature}</p>
                </div>
                <div className="weather-desc">
                    <div className="windy-desc">
                        <p className="windy">{windSpeed}</p>
                        <div>{<RiWindyFill size={45} color="white"/>}</div>
                    </div>
                    <div className="windy-desc">
                        <p className="windy">{humidity}</p>
                        <div>{<WiHumidity size={45} color="white"/>}</div>
                    </div>
                </div>
                <div className="title-weather">
                    <h3>Consuming APIs</h3>
                </div>
            </div>
            <Toaster
                position="bottom-center"
                reverseOrder={false}
            />
        </section>
    )
};

export default Weather