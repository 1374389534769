import "../../../styles/skills/ux/ux.css";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';

const UxSkill = () => {
    const [ xPosition, setXPosition ] = useState<number>(0);
    const [ yPosition, setYPosition ] = useState<number>(0);
    const [ rotate, setRotate ] = useState<number>(180);
    const [ maxX, setMaxX ] = useState<number>(200);
    const [ minX, setMinX ] = useState<number>(-200);

    const handleChange = (setState: React.Dispatch<React.SetStateAction<any>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
        setState(Number(e.target.value))
    };

    useEffect(() => {
        const width: number = window.innerWidth;

        switch(true) {
            case width < 680:
                setMaxX(90);
                setMinX(-90);
                break;
            case width > 790:
                setMaxX(200);
                setMinX(-200);
                break;
        }
    }, []);

    return (
        <section className="uxContainer">
            <div className="preview">
                <motion.div 
                animate={{
                        x: xPosition,
                        y: yPosition,
                        scale: 2,
                        rotate: rotate,
                }}>
                    <div className="withe-box"></div>
                </motion.div>
            </div>
            <div className="box-configs">
                <div className="title-ux">
                    <h3>UX</h3>
                </div>
                <div className="xPosition">
                    <Form.Label>X</Form.Label>
                    <input
                        type="range"
                        min={minX}
                        max={maxX}
                        value={xPosition}
                        onChange={handleChange(setXPosition)}
                    />
                </div>
                <div className="xPosition">
                    <Form.Label>Y</Form.Label>
                    <input
                        type="range"
                        min={-40}
                        max={40}
                        value={yPosition}
                        onChange={handleChange(setYPosition)}
                    />
                </div>
                <div className="xPosition">
                    <Form.Label>Rotate</Form.Label>
                    <input
                        type="range"
                        min={0}
                        max={360}
                        value={rotate}
                        onChange={handleChange(setRotate)}
                    />
                </div>
            </div>
        </section>
    )
};

export default UxSkill;