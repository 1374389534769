import "../../styles/skills/skillContainer.css";
import SkillProps from "../../interfaces/props/skillContainer.interface";
import skillList from "./skillsLIst";
import React, { useEffect, useState } from "react";

const SkillContainer: React.FC<SkillProps> = (Props)=> {
    const [index, setIndex] = useState<number>(0);

    useEffect(() => {
        setIndex(Props.Element);
    }, []);

    return (
        <section className={`${index > 1 ? "skill-second" : "skill-first"} skill`}>
            <div className={`${index > 1 ? "second-line" : "first-line"} sContainer`}>
                {skillList[index]}
            </div>
        </section>
    )
};

export default SkillContainer;