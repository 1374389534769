import "../styles/skills.css";
import skillList from "./skills/skillsLIst";
import SkillContainer from "./skills/skillContainer";

const SkillsContent = () => {
    return (
        <section className="skills">
            <div className="skills-content">
                {skillList.map((Skill, index) => (
                    <SkillContainer key={index} Element={index}/>
                ))}
            </div>
        </section>
    )
};

export default SkillsContent;