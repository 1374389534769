import "../styles/cover.css";
import Button from 'react-bootstrap/Button';

const Cover = () => {
    const hideCover = (e: any) => {
        e.preventDefault();
        const cover = document.getElementById("cover");
        const container = document.getElementById("container-page");
        cover!.classList.add('hide');
        container!.classList.remove("max-height");
    };

    return (
        <section className="cover" id="cover">
            <div className="border-cover">
                <div className="cover-hi">
                    <h1>Hi...</h1>
                </div>
                <div className="cover-button">
                    <Button variant="outline-dark" className="hide-button" onClick={hideCover}>START</Button>
                </div>
            </div>
        </section>
    )
};

export default Cover;