import UxSkill from "./ux/ux";
import FormExample from "./form/form";
import Weather from "./api/weather";

const skillList = [
    <UxSkill/>,
    <FormExample/>,
    <Weather/>
];

export default skillList;