import "../../../styles/projects/houses/housesStyles.css";

const houses = () => {
    const redirect = (e: any) => {
        e.preventDefault();
        window.open("https://eager-clarke-e7d259.netlify.app"); 
    };
    
    return (
        <section className="houses">
            <div className="divided-bg"></div>
                <div className="house-container" onClick={redirect}>
                    <div className="img-bac"></div>
                    <div className="text-desc">
                        <p>Our real estate project aims to offer more than just selling houses. We focus on providing our clients with simple and clear ideas to improve their homes, transforming their spaces into cozy, functional, and personalized places.</p>
                    </div>
                </div>
            <div className="pagination-3">
                    <p>03/05 <span className="line">_____________</span></p>
            </div>
            <div className="title-box-2">
                    <h1>Real Estate</h1>
            </div>
        </section>
    )
};

export default houses;